import catImage from '../assets/cat-pic.jpg';
import catImage2 from '../assets/cat-pic2.jpg';

const RedirectMessage = () => {
  const containerStyle = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightblue', // Change this to your desired color
  };

  const messageStyle = {
    fontSize: '24px',
    color: '#333',
    textAlign: 'center',
    padding: '20px',
  };

  const imageStyle = {
    width: '200px',
    marginBottom: '20px',
    mixBlendMode: 'multiply',
    filter: 'brightness(1.1)',
    borderRadius: '10px', // Optional: rounds the corners of the image
  };

  return (
    <div style={containerStyle}>
      <img
        src={catImage2}
        alt="Cat"
        style={imageStyle}
      />
      <h1 style={messageStyle}>
        Looks like you're looking for an AI expert! 😺
      </h1>
      <p style={{...messageStyle, fontSize: '18px'}}>
        Please enter the complete URL to access your specialized AI assistant
      </p>
    </div>
  );
}

export default RedirectMessage;
