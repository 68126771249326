import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ChatMessage from './chat-message';
import Rating from './rating';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import SideBar from './side-bar';

import LocationIcon from './assets/location-icon.svg';
import ClockIcon from './assets/clock-icon.svg';
import WebIcon from './assets/web-icon.svg';
import EmailIcon from './assets/email-icon.svg';
import PhoneIcon from './assets/phone-icon.svg';
import ImavenIcon from './assets/imaven-icon.svg';
import PageIcon from './assets/page-icon.svg';
import NightModeIcon from './assets/night-mode-icon.svg';
import LightModeIcon from './assets/light-mode-icon.svg';
import MicIcon from './assets/mic-icon.svg';
import MicIconDark from './assets/mic-icon-dark.svg';
import MicRecordingIcon from './assets/mic-recording-icon.svg';
import MicRecordingIconDark from './assets/mic-recording-icon-dark.svg';

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: ${(props) => (props.darkMode ? 'rgb(30, 30, 30)' : 'rgb(246, 248, 252)')};
  overflow: hidden;
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${(props) => (props.darkMode ? '#333' : 'white')};
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 3vh;
`;

const MobileMenuButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.darkMode ? '#ddd' : '#333')};
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
`;

const MobileSidebar = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.isOpen ? '0' : '-100%'};
  width: 80%;
  height: 100vh;
  background: ${(props) => 
    props.darkMode 
      ? 'linear-gradient(145deg, #1a1a1a 0%, #242424 100%)' 
      : 'linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%)'
  };
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '5px 0 15px rgba(0, 0, 0, 0.5)' 
      : '5px 0 15px rgba(0, 0, 0, 0.1)'
  };
`;

const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const ExpertImageWrapper = styled.div`
  width: 100%;
  height: 247px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 8px 20px rgba(0, 0, 0, 0.4)' 
      : '0 8px 20px rgba(0, 0, 0, 0.1)'
  };
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.02);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const ExpertTitle = styled.div`
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
  width: 100%;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 30.5px;
  text-align: left;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
  padding: 0 0 15px 0;
  margin-bottom: 20px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60%;
    background: linear-gradient(to right, 
      ${(props) => (props.darkMode ? '#666666' : '#0077b6')} 0%, 
      transparent 100%
    );
  }
`;

const ExpertName = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 30.5px;
  text-align: left;
  margin-bottom: 4px;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
`;

const ExpertRole = styled.div`
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 8px;
  color: ${(props) => (props.darkMode ? '#aaa' : 'inherit')};
`;

const InfoContainer = styled.div`
  padding: 0;
  color: ${(props) => (props.darkMode ? '#ccc' : '#333')};
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 8px;

  transition: all 0.3s ease;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 2px 8px rgba(0, 0, 0, 0.3)' 
      : '0 2px 8px rgba(0, 0, 0, 0.05)'
  };
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: ${(props) => 
      props.darkMode 
        ? '0 4px 12px rgba(0, 0, 0, 0.4)' 
        : '0 4px 12px rgba(0, 0, 0, 0.1)'
    };
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.4;
  flex: 1;
  padding-left: 10px;
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
`;

const HighlightedText = styled.span`
  color: ${(props) => (props.darkMode ? '#7CFC00' : '#00a86b')};
  font-weight: 500;
`;

const GrayText = styled.span`
  color: ${(props) => (props.darkMode ? '#aaa' : '#777')};
`;

const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(props) => 
    props.darkMode 
      ? 'rgba(102, 102, 102, 0.2)' 
      : 'rgba(0, 119, 182, 0.1)'
  };
  color: ${(props) => (props.darkMode ? '#999999' : '#0077b6')};
  transition: all 0.3s ease;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 2px 5px rgba(0, 0, 0, 0.3)' 
      : '0 2px 5px rgba(0, 0, 0, 0.1)'
  };
`;

const SendMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => (props.darkMode ? '#555' : 'lightgray')};

  border-radius: 12px;
  padding: 0.5rem;
  margin: 10px;
  width: calc(100% - 20px);
  position: absolute;
  bottom: 10px;
  left: 0;
  box-sizing: border-box;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 2px 8px rgba(0, 0, 0, 0.3)' 
      : '0 2px 8px rgba(0, 0, 0, 0.05)'
  };
  transition: all 0.3s ease;
  
  &:focus-within {
    box-shadow: ${(props) => 
      props.darkMode 
        ? '0 4px 12px rgba(0, 0, 0, 0.4)' 
        : '0 4px 12px rgba(0, 0, 0, 0.1)'
    };
    border-color: ${(props) => (props.darkMode ? '#666' : '#0077b6')};
  }
`;

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  background: ${(props) => 
    props.darkMode 
      ? 'rgba(0, 119, 182, 0.2)' 
      : 'rgba(0, 119, 182, 0.1)'
  };
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin: 0 5px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    background: ${(props) => 
      props.darkMode 
        ? 'rgba(102, 102, 102, 0.3)' 
        : 'rgba(0, 119, 182, 0.2)'
    };
  }
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => (props.darkMode ? '#ccc' : '#333')};
  margin-left: 0.5rem;
  flex-grow: 1;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  padding: 8px 0;
  
  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;

const MessagesContiner = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;

  -webkit-overflow-scrolling: touch;
  margin-bottom: 0;
  height: 80%;
  border-radius: 8px;
  margin: 15px;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const ChatWindow = styled.div`
  background: ${(props) => 
    props.darkMode 
      ? 'linear-gradient(145deg, #1a1a1a 0%, #242424 100%)' 
      : 'linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%)'
  };
  color: ${(props) => (props.darkMode ? '#e0e0e0' : '#222')};
  border-radius: 12px;
  box-shadow: ${(props) => 
    props.darkMode 
      ? 'inset 0 0 15px rgba(0, 0, 0, 0.3)' 
      : 'inset 0 0 15px rgba(0, 0, 0, 0.05)'
  };
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 96%;
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 60px;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: ${(props) => (props.darkMode ? '#ddd' : '#333')};
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  z-index: 9999;
`;

const HelpButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => (props.darkMode ? '#ddd' : '#333')};
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  margin: 0 10px;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const RecordingIcon = styled(IconWrapper)`
  animation: ${pulse} 1.5s ease-in-out infinite;
  background: ${props => props.darkMode ? 'rgba(255, 107, 107, 0.2)' : 'rgba(255, 0, 0, 0.1)'};
  
  &:hover {
    background: ${props => props.darkMode ? 'rgba(255, 107, 107, 0.3)' : 'rgba(255, 0, 0, 0.2)'};
  }
`;

const RecordingTimer = styled.div`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.darkMode ? '#333' : '#f0f0f0'};
  color: ${props => props.darkMode ? '#ff6b6b' : '#ff0000'};
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const RecordingStatus = styled.div`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.darkMode ? '#333' : '#f0f0f0'};
  color: ${props => props.darkMode ? '#ccc' : '#333'};
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ChatPageMobile = ({
    darkMode,
    messages,
    expertData,
    sidebarOpen,
    toggleSidebar,
    toggleMode,
    listRef,
    handleMessagesContainerClick,
    loadingMessage,
    emojiPickerOn,
    data,
    emojiSelected,
    uploadRef,
    handleFileChange,
    uploadDoc,
    openEmojiPicker,
    inputRef,
    currentMessage,
    handleInputChange,
    sendMessage,
    apikey,
    base_url,
    isRecording,
    recordingTime,
    toggleRecording,
    isTranscribing,
    transcriptionStatus,
    formatTime
}) => {
    const [helpSidebarOpen, setHelpSidebarOpen] = useState(false);

    // Log microphone props
    console.log("Mobile Mic Props:", { 
        isRecording, recordingTime, toggleRecording, 
        isTranscribing, transcriptionStatus, formatTime,
        isMicIconVisible: !!toggleRecording
    });

    const toggleHelpSidebar = () => {
        setHelpSidebarOpen(!helpSidebarOpen);
    };

    return (
        <MobileContainer darkMode={darkMode}>
            <MobileHeader darkMode={darkMode}>
                <MobileMenuButton onClick={toggleSidebar} darkMode={darkMode}>
                    ☰
                </MobileMenuButton>
                <img src={ImavenIcon} alt="Imaven" height="30px" />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <HelpButton onClick={toggleHelpSidebar} darkMode={darkMode}>
                        ?
                    </HelpButton>
                    <div onClick={toggleMode}>
                        <img src={darkMode ? LightModeIcon : NightModeIcon} alt="Theme" width="20px" />
                    </div>
                </div>
            </MobileHeader>

            <MobileOverlay isOpen={helpSidebarOpen} onClick={toggleHelpSidebar} />
            <MobileSidebar darkMode={darkMode} isOpen={helpSidebarOpen}>
                <div style={{ padding: '20px', position: 'relative' }}>
                    <CloseButton onClick={toggleHelpSidebar} darkMode={darkMode}>
                        ×
                    </CloseButton>
                    <SideBar darkMode={darkMode} />
                </div>
            </MobileSidebar>

            <MobileOverlay isOpen={sidebarOpen} onClick={toggleSidebar} />
            <MobileSidebar darkMode={darkMode} isOpen={sidebarOpen}>
                <div style={{ padding: '20px', position: 'relative' }}>
                    <CloseButton onClick={toggleSidebar} darkMode={darkMode}>
                        ×
                    </CloseButton>
                    <h2 style={{ color: darkMode ? '#fff' : '#000' }}>Adviser Info</h2>
                    <ExpertImageWrapper>
                        <img
                            src={expertData.imgUrl}
                            width={'100%'}
                            height={'100%'}
                            alt='expert image'
                            style={{ borderRadius: '10px' }}
                        />
                    </ExpertImageWrapper>
                    <ExpertTitle darkMode={darkMode}>
                        <div style={{ height: '100%', padding: '20px' }}>
                            <ExpertName darkMode={darkMode}>{expertData.expertName}</ExpertName>
                            <ExpertRole>{expertData.expertTilte}</ExpertRole>
                            <Rating rating={expertData.stars} reviews={expertData.reviewAmount} />
                        </div>
                    </ExpertTitle>

                    <InfoContainer>
                        <ContactItem>
                            <InfoIconWrapper darkMode={darkMode}>
                                <img src={LocationIcon} width={'25px'} alt='location icon' />
                            </InfoIconWrapper>
                            <Text darkMode={darkMode}>{expertData.expertInfo.location}</Text>
                        </ContactItem>

                        <ContactItem>
                            <InfoIconWrapper darkMode={darkMode}>
                                <img src={ClockIcon} alt='clock icon' />
                            </InfoIconWrapper>
                            <Text darkMode={darkMode}>
                                <HighlightedText darkMode={darkMode}>Open</HighlightedText> · Closes {expertData.expertInfo.hourClose}
                            </Text>
                        </ContactItem>

                        <ContactItem>
                            <InfoIconWrapper darkMode={darkMode}>
                                <img src={WebIcon} alt='web icon' />
                            </InfoIconWrapper>
                            <Text darkMode={darkMode}>{expertData.expertInfo.site}</Text>
                        </ContactItem>

                        <ContactItem>
                            <InfoIconWrapper darkMode={darkMode}>
                                <img src={EmailIcon} alt='email icon' />
                            </InfoIconWrapper>
                            <Text darkMode={darkMode}>{expertData.expertInfo.email}</Text>
                        </ContactItem>

                        <ContactItem>
                            <InfoIconWrapper darkMode={darkMode}>
                                <img src={PhoneIcon} alt='phone icon' />
                            </InfoIconWrapper>
                            <Text darkMode={darkMode}>{expertData.expertInfo.phone}</Text>
                        </ContactItem>

                        <ContactItem>
                            <InfoIconWrapper darkMode={darkMode}>
                                <img src={PageIcon} alt='page icon' />
                            </InfoIconWrapper>
                            <Text darkMode={darkMode}>{expertData.expertInfo.about}</Text>
                        </ContactItem>
                    </InfoContainer>
                </div>
            </MobileSidebar>

            <ChatWindow darkMode={darkMode} style={{ flex: 1, margin: '10px' }}>
                <MessagesContiner
                    ref={listRef}
                    darkMode={darkMode}
                    onClick={handleMessagesContainerClick}
                    style={{ height: 'calc(100vh - 180px)' }}
                >
                    {messages.map((item, index) => (
                        <ChatMessage
                            message={item.message}
                            type={item.type}
                            apikey={apikey}
                            darkMode={darkMode}
                            key={index}
                            loading={loadingMessage && index === messages.length - 1}
                            timestamp={item.timestamp}
                        />
                    ))}
                </MessagesContiner>

                <div style={{ position: 'relative', padding: '10px',height: '10%' }}>
                    {emojiPickerOn && (
                        <div style={{
                            bottom: '138%',
                            position: 'absolute',
                            left: "1%",
                            zIndex: '100',
                            width: '100%'
                        }}>
                            <Picker
                                data={data}
                                onEmojiSelect={emojiSelected}
                                theme="light"
                                style={{ width: '100%' }}
                            />
                        </div>
                    )}
                    <SendMessageWrapper darkMode={darkMode}>
                        <input
                            name="files"
                            type="file"
                            id="file-input"
                            style={{ display: 'none' }}
                            ref={uploadRef}
                            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                            multiple
                            onChange={handleFileChange}
                            darkMode={darkMode}
                        />

                        <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                            <img src={`${base_url}/icons/upload-icon?api_key=${apikey}`}
                                alt="upload icon" />
                        </IconWrapper>

                        <IconWrapper disabled={loadingMessage} onClick={openEmojiPicker}>
                            <img src={`${base_url}/icons/emoji-icon?api_key=${apikey}`}
                                alt="emoji icon" />
                        </IconWrapper>

                        <Input
                            ref={inputRef}
                            placeholder='Type your message...'
                            type='text'
                            value={currentMessage}
                            onChange={handleInputChange}
                            disabled={loadingMessage}
                            onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()}
                            darkMode={darkMode}
                            autoFocus={false}
                        />

                        {currentMessage.trim() ? (
                            <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                <img src={`${base_url}/icons/send-btn?api_key=${apikey}`}
                                    alt="send icon" />
                            </IconWrapper>
                        ) : (
                            isRecording ? (
                                <RecordingIcon 
                                    onClick={toggleRecording} 
                                    darkMode={darkMode}
                                    style={{ marginRight: '5px' }}
                                >
                                    <img 
                                        src={darkMode ? MicRecordingIconDark : MicRecordingIcon}
                                        alt="Stop recording" 
                                    />
                                    <RecordingTimer darkMode={darkMode}>
                                        {formatTime(recordingTime)}
                                    </RecordingTimer>
                                </RecordingIcon>
                            ) : (
                                <IconWrapper 
                                    onClick={toggleRecording} 
                                    disabled={loadingMessage || isTranscribing}
                                    darkMode={darkMode}
                                    style={{ marginRight: '5px' }}
                                >
                                    <img 
                                        src={darkMode ? MicIconDark : MicIcon}
                                        alt="Start recording" 
                                    />
                                    {isTranscribing && (
                                        <RecordingStatus darkMode={darkMode}>
                                            {transcriptionStatus}
                                        </RecordingStatus>
                                    )}
                                </IconWrapper>
                            )
                        )}
                    </SendMessageWrapper>
                </div>
            </ChatWindow>
        </MobileContainer>
    );
};

export default ChatPageMobile;
