import React, { useState } from 'react';
import styled from 'styled-components';

// More modern container with subtle gradient and glass effect
const TutorialContainer = styled.div`
  padding: 24px;

  height: 96%;
  overflow-y: auto;
  background: ${(props) => 
    props.darkMode 
      ? 'linear-gradient(145deg, #1a1a1a 0%, #242424 100%)' 
      : 'linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%)'
  };
  color: ${(props) => (props.darkMode ? '#e0e0e0' : '#222')};
  border-radius: 12px;
  box-shadow: ${(props) => 
    props.darkMode 
      ? 'inset 0 0 15px rgba(0, 0, 0, 0.3)' 
      : 'inset 0 0 15px rgba(0, 0, 0, 0.05)'
  };
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

// More tech-looking buttons with modern styling
const CollapsibleButton = styled.button`
  background: ${(props) => 
    props.darkMode 
      ? 'linear-gradient(to right, #2a2a2a, #1f1f1f)'
      : 'linear-gradient(to right, #ffffff, #f5f7fa)'
  };
  color: ${(props) => (props.darkMode ? '#e0e0e0' : '#333')};
  cursor: pointer;
  padding: 16px;
  width: 100%;
  border: none;
  border-radius: 8px;
  text-align: left;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 2px 8px rgba(0, 0, 0, 0.4)' 
      : '0 2px 8px rgba(0, 0, 0, 0.05)'
  };
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: ${(props) => 
      props.darkMode 
        ? '0 4px 12px rgba(0, 0, 0, 0.5)' 
        : '0 4px 12px rgba(0, 0, 0, 0.1)'
    };
    transform: translateY(-2px);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: ${(props) => 
      props.isOpen 
        ? (props.darkMode ? '#1E1E1E' : '#0077b6') 
        : 'transparent'
    };
    transition: all 0.3s ease;
  }
`;

// Modern icon with animation
const IconSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${(props) => 
    props.darkMode 
      ? 'rgba(102, 102, 102, 0.2)' 
      : 'rgba(0, 119, 182, 0.1)'
  };
  color: ${(props) => (props.darkMode ? '#999999' : '#0077b6')};
  font-size: 18px;
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;

// Enhanced content area with better transitions
const Content = styled.div`
  padding: ${({ isOpen }) => (isOpen ? '15px 20px' : '0 20px')};
  max-height: ${({ isOpen }) => (isOpen ? '800px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden;
  background: ${(props) => 
    props.darkMode 
      ? 'rgba(26, 26, 26, 0.8)' 
      : 'rgba(255, 255, 255, 0.8)'
  };
  font-size: 15px;
  border-left: 3px solid ${(props) => 
    props.darkMode ? '#4b6f86' : '#0077b6'
  };
  margin-bottom: 15px;
  margin-left: 10px;
  border-radius: 0 8px 8px 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 2px 10px rgba(0, 0, 0, 0.3)' 
      : '0 2px 10px rgba(0, 0, 0, 0.03)'
  };
`;

// Tech-styled highlights
const Highlight = styled.span`
  background: ${(props) => 
    props.darkMode 
      ? 'linear-gradient(90deg, rgba(102, 102, 102, 0.2) 0%, rgba(102, 102, 102, 0.1) 100%)' 
      : 'linear-gradient(90deg, rgba(0, 119, 182, 0.1) 0%, rgba(0, 119, 182, 0.05) 100%)'
  };
  padding: 4px 8px;
  border-radius: 4px;
  color: ${(props) => (props.darkMode ? '#999999' : '#0077b6')};
  font-family: 'Roboto Mono', monospace;
  font-size: 0.9em;
  border: 1px solid ${(props) => 
    props.darkMode ? 'rgba(136, 19, 143, 0.97)' : 'rgba(0, 119, 182, 0.2)'
  };
  display: inline-block;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 2px 4px rgba(0, 0, 0, 0.2)' 
      : '0 2px 4px rgba(0, 0, 0, 0.05)'
  };
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: ${(props) => 
      props.darkMode 
        ? '0 4px 8px rgba(0, 0, 0, 0.3)' 
        : '0 4px 8px rgba(0, 0, 0, 0.1)'
    };
  }
`;

// Note box with more tech-style
const HighlightNote = styled.div`
  color: ${(props) => (props.darkMode ? '#f5a623' : '#fb8500')};
  background: ${(props) => 
    props.darkMode 
      ? 'linear-gradient(to right, rgba(41, 41, 41, 0.9), rgba(27, 27, 27, 0.9))'
      : 'linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(248, 249, 250, 0.9))'
  };
  padding: 12px 16px;
  border-radius: 8px;
  margin: 15px 0;
  position: relative;
  box-shadow: ${(props) => 
    props.darkMode 
      ? '0 3px 10px rgba(0, 0, 0, 0.3)' 
      : '0 3px 10px rgba(0, 0, 0, 0.05)'
  };
  border-left: 4px solid ${(props) => (props.darkMode ? '#f5a623' : '#fb8500')};
  display: flex;
  align-items: center;

  &:before {
    content: '💡';
    margin-right: 10px;
    font-size: 18px;
  }
`;

// Updated link style
const StyledLink = styled.a`
  color: ${(props) => (props.darkMode ? '#999999' : '#0077b6')};
  text-decoration: none;
  position: relative;
  padding-bottom: 2px;
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${(props) => (props.darkMode ? '#999999' : '#0077b6')};
    transition: width 0.3s ease;
  }
  
  &:hover:after {
    width: 100%;
  }
`;

// Tech-styled list items
const ListItem = styled.li`
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  list-style: none;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => (props.darkMode ? '#666666' : '#0077b6')};
  }
`;

// Heading with tech style
const Heading = styled.h2`
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
  padding-bottom: 10px;
  font-weight: 600;
  color: ${(props) => (props.darkMode ? '#ffffff' : '#222')};
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60%;
    background: linear-gradient(to right, 
      ${(props) => (props.darkMode ? '#666666' : '#0077b6')} 0%, 
      transparent 100%
    );
  }
`;

const AdvisorHeading = styled.h2`
  color: ${(props) => (props.darkMode ? 'white' : 'black')};
  margin-bottom: 20px;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  font-weight: 500;
  font-size: 24px;
`;

const CollapsibleSection = ({ title, children, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    // Track the section click with Plausible
    if (window.plausible) {
      window.plausible('TutorialSectionClick', {
        props: { section: title }
      });
    }
  };

  return (
    <div>
      <CollapsibleButton onClick={toggleOpen} darkMode={darkMode} isOpen={isOpen}>
        {title}
        <IconSpan darkMode={darkMode} isOpen={isOpen}>
          ›
        </IconSpan>
      </CollapsibleButton>
      <Content isOpen={isOpen} darkMode={darkMode}>{children}</Content>
    </div>
  );
};

function SideBar({ darkMode }) {
  return (
    <TutorialContainer darkMode={darkMode}>
      <Heading darkMode={darkMode}>Using the iMaven Assistant</Heading>

      <CollapsibleSection title="Process Flow" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>Identify Your Needs</ListItem>
          <ListItem darkMode={darkMode}>Gather Detailed Information</ListItem>
          <ListItem darkMode={darkMode}>Analyze and Summarize</ListItem>
          <ListItem darkMode={darkMode}>Connect to Human Advisor</ListItem>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Purpose" darkMode={darkMode}>
        <p><strong>AI preps for expert advisor meeting.</strong></p>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Getting Started" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>Type queries in the bottom box.</ListItem>
          <ListItem darkMode={darkMode}>Hit <Highlight darkMode={darkMode}>Enter</Highlight> or send to submit.</ListItem>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Information Gathering" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>AI asks about your situation.</ListItem>
          <ListItem darkMode={darkMode}>Provide <strong>honest, detailed answers</strong>.</ListItem>
          <ListItem darkMode={darkMode}>More detailed info = better results.</ListItem>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Attaching Documents" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>AI may request relevant files.</ListItem>
          <ListItem darkMode={darkMode}>Click <Highlight darkMode={darkMode}>Attach</Highlight> to upload (5MB limit).</ListItem>
          <ListItem darkMode={darkMode}>Accepted formats: <strong>txt, pdf, docx, xlsx, xls, csv, json, md, html, epub, pptx, ppt, doc</strong>.</ListItem>
          <ListItem darkMode={darkMode}>If you can't attach now, no problem — provide files to your advisor later.</ListItem>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Tips for Best Results" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}><strong>Be specific</strong> about your needs.</ListItem>
          <ListItem darkMode={darkMode}>Share relevant details of your situation.</ListItem>
          <ListItem darkMode={darkMode}>Feel free to ask if anything is unclear.</ListItem>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Next Steps" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>AI will summarize your information.</ListItem>
          <ListItem darkMode={darkMode}>You will receive a link to schedule a meeting with an advisor.</ListItem>
          <ListItem darkMode={darkMode}>Your advisor reviews the info before the meeting.</ListItem>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Need Help?" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>Technical issues? Contact: <StyledLink darkMode={darkMode} href="mailto:support@imavenai.com">support@imavenai.com</StyledLink></ListItem>
        </ul>
      </CollapsibleSection>

      <CollapsibleSection title="Privacy" darkMode={darkMode}>
        <ul>
          <ListItem darkMode={darkMode}>Your information is kept <strong>confidential</strong>.</ListItem>
          <ListItem darkMode={darkMode}>Only share what you're comfortable with.</ListItem>
          <ListItem darkMode={darkMode}>Remember: Detailed info and relevant documents lead to more accurate advice!</ListItem>
        </ul>
      </CollapsibleSection>

    </TutorialContainer>
  );
}

export default SideBar;