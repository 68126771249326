import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatPage from './chat-page';
import GlobalStyle from './globel-style';
import RedirectMessage from './components/RedirectMessage';

function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Router>
        <Routes>
          <Route path="/chat/:apikey" element={<ChatPage />} />
          <Route path="*" element={<RedirectMessage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
