import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ChatMessage from './chat-message';
import styled, { css, keyframes } from 'styled-components';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Rating from './rating';
import SideBar from './side-bar';
import ChatPageMobile from './chat-page-mobile';

import LocationIcon from './assets/location-icon.svg';
import ClockIcon from './assets/clock-icon.svg';
import WebIcon from './assets/web-icon.svg';
import EmailIcon from './assets/email-icon.svg';
import PhoneIcon from './assets/phone-icon.svg';
import ImavenIcon from './assets/imaven-icon.svg'
import LoadingIcon from './assets/load-line.svg'
import PageIcon from './assets/page-icon.svg'
import NightModeIcon from './assets/night-mode-icon.svg'
import LightModeIcon from './assets/light-mode-icon.svg'
import MicIcon from './assets/mic-icon.svg'
import MicIconDark from './assets/mic-icon-dark.svg'
import MicRecordingIcon from './assets/mic-recording-icon.svg'
import MicRecordingIconDark from './assets/mic-recording-icon-dark.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${(props) =>
        props.darkMode
            ? 'linear-gradient(145deg, #1a1a1a 0%, #242424 100%)'
            : 'linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%)'
    };
  color: ${(props) => (props.darkMode ? '#e0e0e0' : '#222')};
  border-radius: 12px;
  box-shadow: ${(props) =>
        props.darkMode
            ? 'inset 0 0 15px rgba(0, 0, 0, 0.3)'
            : 'inset 0 0 15px rgba(0, 0, 0, 0.05)'
    };
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  height: 96%;
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

export const ChatWindow = styled.div`
  background: ${(props) =>
        props.darkMode
            ? 'linear-gradient(145deg, #1a1a1a 0%, #242424 100%)'
            : 'linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%)'
    };
  color: ${(props) => (props.darkMode ? '#e0e0e0' : '#222')};
  border-radius: 12px;
  box-shadow: ${(props) =>
        props.darkMode
            ? 'inset 0 0 15px rgba(0, 0, 0, 0.3)'
            : 'inset 0 0 15px rgba(0, 0, 0, 0.05)'
    };
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
  width: 100%;
  max-width: ${props => props.isMobile ? '100%' : 'none'};
  margin: ${props => props.isMobile ? '0 auto' : '0'};
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

export const IFrameChatWindow = styled.div`
  background-color: ${(props) => (props.darkMode ? '#222' : 'white')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
  border-radius: 8px;
  min-width: 93%;
  max-width: 400px;
  height: 76vh;
  padding: 5px 10px 5px 10px;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: ${(props) => (props.darkMode ? '#444' : '#007BFF')};
  color: ${(props) => (props.darkMode ? '#ddd' : 'white')};
  border: none;
  border-radius: 5px;
  font-size: 16px;
`;

const SendMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => (props.darkMode ? '#555' : 'lightgray')};
  background: ${(props) =>
        props.darkMode
            ? 'linear-gradient(to right, rgba(41, 41, 41, 0.8), rgba(27, 27, 27, 0.8))'
            : 'linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(248, 249, 250, 0.9))'
    };
  border-radius: 12px;
  padding: 0.5rem;
  margin: ${props => props.isMobile ? '10px' : '0 15px'};
  bottom: 10px;
  left: 0;
  right: 0;
  max-width: ${props => props.isMobile ? 'calc(100% - 20px)' : 'none'};
  box-shadow: ${(props) =>
        props.darkMode
            ? '0 2px 8px rgba(0, 0, 0, 0.3)'
            : '0 2px 8px rgba(0, 0, 0, 0.05)'
    };
  transition: all 0.3s ease;
  
  &:focus-within {
    box-shadow: ${(props) =>
        props.darkMode
            ? '0 4px 12px rgba(0, 0, 0, 0.4)'
            : '0 4px 12px rgba(0, 0, 0, 0.1)'
    };
    border-color: ${(props) => (props.darkMode ? '#666' : '#0077b6')};
  }
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  background: ${(props) =>
        props.darkMode
            ? 'rgba(0, 119, 182, 0.1)'
            : 'rgba(0, 119, 182, 0.1)'
    };
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin: 0 5px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    background: ${(props) =>
        props.darkMode
            ? 'rgba(0, 119, 182, 0.1)'
            : 'rgba(0, 119, 182, 0.2)'
    };
  }
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => (props.darkMode ? '#ccc' : '#333')};
  margin-left: 0.5rem;
  flex-grow: 1;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  padding: 8px 0;

  &:focus {
    outline: none;
  }
`;

const MessagesContiner = styled.div`
  height: 90%;
  margin-bottom: 10px;
  overflow-y: auto;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;

  border-radius: 8px;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const Header = styled.div`
  height: 5vh;
  width: 100%;
  background-color: ${(props) => (props.darkMode ? '#444' : 'white')};
  border-right: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
`;

const SideHeader = styled.div`
  height: 5vh;
  width: 100%;
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
`;

const IframeHeader = styled.div`
  height: 16vh;
  width: 100vw;
  background: ${(props) =>
        props.darkMode ? 'linear-gradient(90deg, #1a1a1a, #2b2b2b)' : 'linear-gradient(90deg, #004c99, #009BF3)'};
  color: white;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const LogoWrapper = styled.div`
  height: 100%;
  position: relative;
  top: var(--logo-vertical-position);
  display: flex;
  align-items: center;
  font-family: 'Inria Sans', sans-serif;
  padding-left: 1px;
`;

const Logo = styled.div`
  background-image: url(${(props) => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 75%;
  padding-right: 5px;
`;

const Footer = styled.div`
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: ${(props) => (props.darkMode ? '#333' : 'red')};
`;

const SideFooter = styled.div`
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: ${(props) => (props.darkMode ? '#2a2a2a' : '#F8FBFF')};
`;

const PoweredByWrapper = styled.div`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
`;

const PoweredBy = styled.a`
  display: flex;
  align-items: center;
`;

const PowerByText = styled.span`
  margin: 0 5px;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
`;

const ExitButtonWrapper = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.darkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.9)')};
  z-index: 9999;
`;

const FadeInWrapper = styled.div`
  opacity: ${(props) => (props.loaded ? 1 : 0)};
  transition: opacity 4s ease-in-out;
`;

const ExpertImageWrapper = styled.div`
  width: 100%;
  height: 247px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: ${(props) =>
        props.darkMode
            ? '0 8px 20px rgba(0, 0, 0, 0.4)'
            : '0 8px 20px rgba(0, 0, 0, 0.1)'
    };
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.02);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const ExpertTitle = styled.div`
  border-bottom: 1px solid ${(props) => (props.darkMode ? '#555' : '#B9CDD8')};
  width: 100%;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 30.5px;
  text-align: left;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
  padding: 0 0 15px 0;
  margin-bottom: 20px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60%;
    background: linear-gradient(to right, 
      ${(props) => (props.darkMode ? '#666666' : '#0077b6')} 0%, 
      transparent 100%
    );
  }
`;

const ExpertName = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 30.5px;
  text-align: left;
  margin-bottom: 4px;
  color: ${(props) => (props.darkMode ? '#ddd' : 'inherit')};
`;

const ExpertRole = styled.div`
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 8px;
  color: ${(props) => (props.darkMode ? '#aaa' : 'inherit')};
`;

const InfoContainer = styled.div`
  padding: 0;
  color: ${(props) => (props.darkMode ? '#ccc' : '#333')};
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 8px;

  transition: all 0.3s ease;
  box-shadow: ${(props) =>
        props.darkMode
            ? '0 2px 8px rgba(0, 0, 0, 0.3)'
            : '0 2px 8px rgba(0, 0, 0, 0.05)'
    };
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: ${(props) =>
        props.darkMode
            ? '0 4px 12px rgba(0, 0, 0, 0.4)'
            : '0 4px 12px rgba(0, 0, 0, 0.1)'
    };
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.4;
  flex: 1;
  padding-left: 10px;
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
`;

const HighlightedText = styled.span`
  color: ${(props) => (props.darkMode ? '#7CFC00' : '#00a86b')};
  font-weight: 500;
`;

const GrayText = styled.span`
  color: ${(props) => (props.darkMode ? '#aaa' : '#777')};
`;

const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(props) =>
        props.darkMode
            ? 'rgba(102, 102, 102, 0.2)'
            : 'rgba(0, 119, 182, 0.1)'
    };
  color: ${(props) => (props.darkMode ? '#999999' : '#0077b6')};
  transition: all 0.3s ease;
  box-shadow: ${(props) =>
        props.darkMode
            ? '0 2px 5px rgba(0, 0, 0, 0.3)'
            : '0 2px 5px rgba(0, 0, 0, 0.1)'
    };
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: ${(props) => (props.darkMode ? '#333' : 'white')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
`;

const PopupButton = styled.button`
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const AdvisorHeading = styled.h2`
  color: ${(props) => (props.darkMode ? 'white' : 'black')};
  margin-bottom: 20px;
  font-family: 'Inter', 'Segoe UI', Roboto, sans-serif;
  font-weight: 500;
  font-size: 24px;
`;

const createMessagesHash = (messages) => messages.map(msg => msg.message).join('|');

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const RecordingIcon = styled(IconWrapper)`
  animation: ${pulse} 1.5s ease-in-out infinite;
  background: ${props => props.darkMode ? 'rgba(255, 107, 107, 0.2)' : 'rgba(255, 0, 0, 0.1)'};
  
  &:hover {
    background: ${props => props.darkMode ? 'rgba(255, 107, 107, 0.3)' : 'rgba(255, 0, 0, 0.2)'};
  }
`;

const RecordingTimer = styled.div`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.darkMode ? '#333' : '#f0f0f0'};
  color: ${props => props.darkMode ? '#ff6b6b' : '#ff0000'};
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const RecordingStatus = styled.div`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.darkMode ? '#333' : '#f0f0f0'};
  color: ${props => props.darkMode ? '#ccc' : '#333'};
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ChatPage = () => {
    const { apikey } = useParams();
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [error, setError] = useState("");
    const [isIframe, setIsIframe] = useState(true);
    const listRef = useRef(null);
    const inputRef = useRef(null);
    const uploadRef = useRef(null);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [emojiPickerOn, setEmojiPickerOn] = useState(false);
    const [darkMode, setDarkMode] = useState(true);
    const [expertData, setExpertData] = useState(
        {
            expertName: '',
            imgUrl: '',
            expertTilte: '',
            stars: 0,
            reviewAmount: 0,
            expertInfo: {
                location: "",
                hourClose: "",
                site: "",
                email: "",
                phone: ""
            },
        })
    const [isMobile, setIsMobile] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState({ href: '', adviserId: '' });
    const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [transcriptionStatus, setTranscriptionStatus] = useState('');
    const [audioChunks, setAudioChunks] = useState([]);
    const [recordingMimeType, setRecordingMimeType] = useState('audio/webm');

    const base_url = process.env.REACT_APP_API_BASE_URL

    useEffect(() => {
        setIsIframe(window.self !== window.top);
    }, []);

    // Add useEffect for mobile detection
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const toggleMode = () => {
        setDarkMode(!darkMode)
    }

    const firstMessage =
        `<p>
            <strong>Welcome!</strong>
            <br/>
            <br/>
            <span>
                Looking for expert advice? We're here to help you make informed decisions. 
                Our AI assistant will guide our conversation to better understand your needs.
            </span>
            <br/>
            <br/>
            <span>
                To provide you with the most valuable guidance, the AI assistant will first gather key information. 
                Then, your professional advisor will review your case and join the conversation with personalized recommendations.
            </span>
        </p>`;

    useEffect(() => {
        if (apikey) {
            const handleEmojiMessage = (event) => {
                if (event.data.action === 'insertEmoji') {
                    const emoji = event.data.emoji;
                    setCurrentMessage((prevMessage) => prevMessage + emoji);
                }
            };

            window.addEventListener('message', handleEmojiMessage);
            const fetchData = async () => {
                try {
                    const data = await fetch(base_url + '/chat/start', {
                        method: 'GET', headers: {
                            'x-apikey': apikey
                        }
                    })

                    if (!data.ok) {
                        throw new Error('Failed to fetch data');
                    }

                    setMessages([
                        { message: firstMessage, type: "system", timestamp: new Date().toLocaleString() }
                    ])

                    const result = await data.json();

                    sendMessageToPlausible('ChatStarted', {
                        conversationId: result.response,
                        timeOfDay: String(new Date().getHours()).padStart(2, '0'),
                        windowType: isIframe ? 'iframe' : 'window',
                        apikey: apikey
                    })

                    setLoading(false)

                    localStorage.setItem('imavenconvoid', result.response)
                    localStorage.setItem('asstId', 'asst_5WqDk3WVgQCoOlJO18quwhlh');
                    localStorage.setItem('directiveId', '67be323b44fccc7abd5b7a08');

                    setExpertData(result.expertData)
                } catch (error) {
                    setError(error);
                    setLoading(false)
                    return
                }



            };

            fetchData();

            return () => {
                window.removeEventListener('message', handleEmojiMessage);
            };
        }
    }, []);

    const messagesHash = createMessagesHash(messages);

    // useEffect(() => {
    //     if (listRef && listRef.current && !isIframe) {
    //         setTimeout(() => {
    //             listRef.current.scrollTop = listRef.current.scrollHeight;
    //         }, 10);
    //     }
    // }, [messagesHash, isIframe]);

    const sendMessageToPlausible = (type, props) => {
        if (window.plausible) {
            window.plausible(type, {
                props: {
                    ...props
                }
            });
        }
    }

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const sendMessage = () => {

        if (currentMessage.trim().length < 2)
            return

        if (window.plausible) {
            const now = new Date();
            const paddedHour = String(now.getHours()).padStart(2, '0');
            window.plausible('ChatMessageSent', {
                props: {
                    messageLength: currentMessage.length,
                    isQuestion: currentMessage.endsWith('?'),
                    timeOfDay: paddedHour,
                    messageCount: messages.length
                },
            });
        }

        setLoadingMessage(true);
        const newMessage = { message: currentMessage, type: 'user', timestamp: new Date().toLocaleString() };
        let temp = [...messages, newMessage]
        setMessages(temp)
        setCurrentMessage("")

        const gptMessage = { message: "", type: 'gpt', timestamp: new Date().toLocaleString() };
        temp.push(gptMessage)
        setMessages(temp)

        setTimeout(() => {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }, 0);

        fetch(base_url + '/chat/sendMessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                'X-Thread-ID': localStorage.getItem('imavenconvoid'),
                'X-Asst-ID': localStorage.getItem('asstId'),
                'X-Directive-ID': localStorage.getItem('directiveId'),
                'x-apikey': apikey
            },
            body: JSON.stringify({ message: currentMessage })
        })
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.message || 'Error sending message. Please try again.');
                }
                return data;
            })
            .then(res => {
                localStorage.setItem('asstId', res.asstId);
                localStorage.setItem('directiveId', res.directiveId);
                updateLastMessage(res.response)

                setTimeout(() => {
                    listRef.current.scrollTop = listRef.current.scrollHeight;
                    // Only focus the input on non-mobile devices
                    if (!isMobile) {
                        inputRef.current.focus();
                    }
                }, 10);

                if (res.finishFlag) {
                    const lastUrl = `
                    <p>
                      <strong>Thank you for sharing your information!</strong>
                      <br/>
                      <span>
                        We are now processing your details and will forward them to a human adviser for further review. 
                        To continue your consultation and connect with an adviser, please 
                        <a
                          id="meeting-link"
                          data-adviserid="${res.id}"
                          data-threadid="${localStorage.getItem('imavenconvoid')}"
                          data-href="https://platform.imavenai.com/setMetting/${res.id}/${localStorage.getItem('imavenconvoid')}"
                          style="color: #0066cc; text-decoration: underline; cursor: pointer;"
                        >
                          click here
                        </a>. 
                        Your conversation will proceed on the Imaven platform.
                      </span>                                   
                    </p>`;

                    const lastMessage = { message: lastUrl, type: 'system', timestamp: new Date().toLocaleString() };

                    setMessages((prevMessages) => [...prevMessages, lastMessage]);

                    // Show popup with the same data
                    setPopupData({
                        href: `https://platform.imavenai.com/setMetting/${res.id}/${localStorage.getItem('imavenconvoid')}`,
                        adviserId: res.id
                    });
                    setShowPopup(true);
                }

            })
            .catch(error => {
                handleError(error);
                console.log(error);
                updateLastMessage(error)

                const systemMessage = `
                    <p>
                        Oops! It looks like we're having some technical difficulties right now.
                        Please give it a moment and try continuing with the conversation shortly.
                        Thank you for your patience!
                    </p>
                `
                const newSystemMessage = {
                    message: systemMessage, type: "system",
                    timestamp: new Date().toLocaleString()
                };

                let temp = [...messages, newSystemMessage]
                setMessages(temp)
            })
            .finally(() => {
                setLoadingMessage(false)
            })
    }

    const updateLastMessage = (message) => {
        setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[newMessages.length - 1] = {
                ...newMessages[newMessages.length - 1],
                message: message,

            };
            return newMessages;
        });
    }

    const handleInputChange = (event) => {
        setCurrentMessage(event.target.value);
    }

    const uploadDoc = () => {
        uploadRef.current.click()
    }

    const handleFileChange = async (event) => {
        setLoadingMessage(true);
        try {
            const files = event.target.files;
            if (files.length === 0)
                return;

            const formData = new FormData();
            let fileNames = [];

            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
                fileNames.push(files[i].name);  // Collect file names
            }

            const userMessage = `File uploaded: ${fileNames.join(", ")}`;
            const newMessage = { message: userMessage, type: 'user', timestamp: new Date().toLocaleString() };
            let temp = [...messages, newMessage];
            setMessages(temp);

            if (window.plausible) {
                const fileCount = files.length;
                const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0);
                const fileTypes = Array.from(new Set(
                    Array.from(files).map(file => file.type)
                )).join(', ');

                const now = new Date();
                const paddedHour = String(now.getHours()).padStart(2, '0');

                window.plausible('FileUpload', {
                    props: {
                        numberOfFiles: fileCount,
                        fileTypes: fileTypes,
                        totalSizeKB: Math.round(totalSize / 1024),
                        messageCount: messages.length,
                        timeOfDay: paddedHour
                    }
                });
            }


            const response = await fetch(base_url + '/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include',
                headers: {
                    'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                    'X-Thread-ID': localStorage.getItem('imavenconvoid'),
                    'X-Directive-ID': localStorage.getItem('directiveId'),
                    'x-apikey': apikey,
                }
            });

            if (response.ok) {
                const gptMessage = { message: "", type: 'gpt', timestamp: new Date().toLocaleString() };
                temp.push(gptMessage);
                setMessages(temp);
                setLoadingMessage(true);

                const aiResponse = await fetch(base_url + '/upload/sendFileData', {
                    body: JSON.stringify({ flag: true }),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                        'X-Thread-ID': localStorage.getItem('imavenconvoid'),
                        'X-Directive-ID': localStorage.getItem('directiveId'),
                        'x-apikey': apikey,
                    }
                });

                const data = await aiResponse.json();
                updateLastMessage(data.response);
            }
        } catch (error) {
            handleError(error);
            console.log("File upload error: ", error);
            updateLastMessage(error);
        } finally {
            setLoadingMessage(false);
        }
    };

    const closeChat = () => {
        if (window.plausible) {
            window.plausible("ChatClosed", {
                props: {
                    totalMessages: messages.length,
                    userMessages: messages.filter(m => m.type === 'user').length,
                    duration: Math.round((Date.now() - window._chatStartTime) / 60000),
                    completionType: messages.some(m => m.message?.includes('meeting-link')) ? 'closed_with_link' : 'closed_before_link',
                    lastMessageType: messages[messages.length - 1]?.type || 'none'
                }
            });
        }
        window.parent.postMessage({ action: 'closeChat' }, '*');
    };

    const openEmojiPickerOutside = () => {
        if (emojiPickerOn) {
            setEmojiPickerOn(false)
        }
    }

    const openEmojiPicker = () => {
        window.parent.postMessage({ action: 'openEmojiPicker' }, '*')
        setEmojiPickerOn(!emojiPickerOn)
    }

    const emojiSelected = (event) => {
        console.log(event);
        setCurrentMessage(currentMessage + event.native)
    }

    const handleMessagesContainerClick = (e) => {
        const meetingLink = e.target.closest('#meeting-link');
        if (meetingLink) {
            e.preventDefault();
            const href = meetingLink.getAttribute('data-href');
            const adviserId = meetingLink.dataset.adviserid;

            if (window.plausible) {
                window.plausible("ChatCompleted", {
                    props: {
                        adviserId: adviserId,
                        totalMessages: messages.length - 2,
                        userMessages: messages.filter(m => m.type === 'user').length,
                        duration: Math.round((Date.now() - window._chatStartTime) / 60000),
                        completionType: 'advisor_link'
                    },
                    callback: () => {
                        window.open(href, '_blank');
                    },
                });
            } else {
                window.open(href, '_blank');
            }
        }
    };

    // Track chat window close
    useEffect(() => {
        // Store chat start time globally
        window._chatStartTime = Date.now();

        const handleBeforeUnload = () => {
            if (window.plausible) {
                // Synchronous tracking for window close
                window.plausible("ChatEnded", {
                    props: {
                        totalMessages: messages.length,
                        userMessages: messages.filter(m => m.type === 'user').length,
                        duration: Math.round((Date.now() - window._chatStartTime) / 60000),
                        completionType: messages.some(m => m.message?.includes('meeting-link')) ? 'abandoned_with_link' : 'abandoned_before_link',
                        lastMessageType: messages[messages.length - 1]?.type || 'none'
                    }
                });
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [messages]);

    // Track errors
    const handleError = (error) => {
        if (window.plausible) {
            window.plausible('ChatError', {
                props: {
                    errorMessage: error.toString(),
                    messageCount: messages.length
                }
            });
        }
        // ... existing error handling
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerOn && !event.target.closest('.emoji-picker')) {
                openEmojiPickerOutside();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiPickerOn]);

    // Add toggle sidebar function
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    // Add handlePopupClick function
    const handlePopupClick = () => {
        if (window.plausible) {
            window.plausible("ChatCompleted", {
                props: {
                    adviserId: popupData.adviserId,
                    totalMessages: messages.length - 2,
                    userMessages: messages.filter(m => m.type === 'user').length,
                    duration: Math.round((Date.now() - window._chatStartTime) / 60000),
                    completionType: 'advisor_popup'
                },
                callback: () => {
                    window.open(popupData.href, '_blank');
                    setShowPopup(false);
                },
            });
        } else {
            window.open(popupData.href, '_blank');
            setShowPopup(false);
        }
    };

    // Helper function to determine the best supported audio MIME type
    const getSupportedMimeType = () => {
        const types = [
            'audio/webm',
            'audio/mp4',
            'audio/ogg',
            'audio/wav',
            'audio/webm;codecs=opus',
            'audio/webm;codecs=pcm',
            'audio/ogg;codecs=opus'
        ];

        for (const type of types) {
            if (MediaRecorder.isTypeSupported(type)) {
                console.log(`Using MIME type: ${type}`);
                return type;
            }
        }
        
        console.warn("No preferred MIME types supported, using default");
        return 'audio/webm'; // Default fallback
    };

    // Format recording time as mm:ss
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // Toggle voice recording with special handling for mobile devices
    const toggleRecording = async () => {
        try {
            if (isRecording) {
                // Stop recording
                if (mediaRecorder && mediaRecorder.state === 'recording') {
                    mediaRecorder.stop();
                    setIsRecording(false);
                    // Clear the recording timer
                    setRecordingTime(0);
                    
                    // Clear any interval timers
                    if (mediaRecorder.timerInterval) {
                        clearInterval(mediaRecorder.timerInterval);
                    }
                }
            } else {
                // Start recording
                setAudioChunks([]);
                
                // Reset transcription state
                setIsTranscribing(false);
                setTranscriptionStatus('');
                
                // Request microphone permission with specific options for mobile
                const constraints = {
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        autoGainControl: true
                    }
                };
                
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                
                // Determine best supported format
                const mimeType = getSupportedMimeType();
                setRecordingMimeType(mimeType);
                console.log(`Using MIME type: ${mimeType} for recording`);
                
                // Create and configure media recorder with better error handling
                const recorder = new MediaRecorder(stream, { 
                    mimeType,
                    audioBitsPerSecond: 128000 // Ensure consistent quality
                });
                
                // Set up data handling
                let chunks = [];
                recorder.ondataavailable = (e) => {
                    if (e.data.size > 0) {
                        chunks.push(e.data);
                        setAudioChunks([...chunks]);
                        console.log(`Received audio chunk: ${e.data.size} bytes`);
                    }
                };
                
                // Handle recording stop
                recorder.onstop = async () => {
                    console.log("Recording stopped");
                    
                    // Mobile devices might need extra time to process
                    setTimeout(() => {
                        // Get all tracks from stream and stop them
                        stream.getTracks().forEach(track => {
                            track.stop();
                            console.log(`Stopped audio track: ${track.kind}`);
                        });
                        
                        // Process audio only if we have chunks
                        if (chunks.length > 0) {
                            console.log(`Processing ${chunks.length} audio chunks`);
                            const audioBlob = new Blob(chunks, { type: mimeType });
                            console.log(`Created audio blob: ${audioBlob.size} bytes`);
                            
                            // Check if recording is too short
                            if (audioBlob.size < 1000) {
                                console.log("Recording too short");
                                setTranscriptionStatus('Recording too short');
                                setTimeout(() => setTranscriptionStatus(''), 3000);
                                return;
                            }
                            
                            // Begin transcription
                            transcribeAudio(audioBlob);
                        } else {
                            console.error("No audio data collected");
                            setTranscriptionStatus('No audio recorded');
                            setTimeout(() => setTranscriptionStatus(''), 3000);
                        }
                    }, 300); // Short delay to ensure all processing is complete
                };
                
                // Extra error handling for mobile devices
                recorder.onerror = (event) => {
                    console.error("MediaRecorder error:", event);
                    setTranscriptionStatus('Recording error');
                    setTimeout(() => setTranscriptionStatus(''), 3000);
                    
                    // Clean up on error
                    stream.getTracks().forEach(track => track.stop());
                    setIsRecording(false);
                };
                
                // Start the actual recording with smaller timeslice for more reliable data collection on mobile
                recorder.start(100); // Get data every 100ms for smoother UX
                setMediaRecorder(recorder);
                setIsRecording(true);
                
                // Set up timer for recording duration with safeguards for mobile interruptions
                let seconds = 0;
                let lastUpdate = Date.now();
                
                const timerInterval = setInterval(() => {
                    const now = Date.now();
                    // Check if more than 2 seconds have passed since last update
                    // This helps detect when device suspends JavaScript execution
                    if (now - lastUpdate > 2000) {
                        const missedSeconds = Math.floor((now - lastUpdate) / 1000);
                        seconds += missedSeconds;
                        console.log(`Detected time gap: ${missedSeconds}s`);
                    }
                    
                    lastUpdate = now;
                    seconds++;
                    setRecordingTime(seconds);
                    
                    // Auto-stop after 2 minutes to prevent excessive large files
                    if (seconds >= 120) {
                        clearInterval(timerInterval);
                        console.log("Auto-stopping after 120 seconds");
                        if (recorder.state === 'recording') {
                            recorder.stop();
                            setIsRecording(false);
                        }
                    }
                    
                    // Check if recorder is still recording
                    if (recorder.state !== 'recording') {
                        clearInterval(timerInterval);
                        console.log("Recorder no longer recording, clearing timer");
                    }
                }, 1000);
                
                // Store timer in mediaRecorder object for cleanup
                recorder.timerInterval = timerInterval;
                
                // Detect page visibility changes which can affect recording on mobile
                const handleVisibilityChange = () => {
                    if (document.hidden && recorder.state === 'recording') {
                        console.log("Page hidden, ensuring recording continues");
                        // On some devices, might need to do something here to keep recording active
                    }
                };
                
                document.addEventListener('visibilitychange', handleVisibilityChange);
                
                // Store cleanup function
                recorder.visibilityCleanup = () => {
                    document.removeEventListener('visibilitychange', handleVisibilityChange);
                };
            }
        } catch (error) {
            console.error("Error toggling recording:", error);
            setTranscriptionStatus('Microphone access error');
            setTimeout(() => setTranscriptionStatus(''), 3000);
            setIsRecording(false);
        }
    };

    // Function to handle audio transcription with mobile-specific handling
    const transcribeAudio = async (audioBlob) => {
        try {
            setIsTranscribing(true);
            setTranscriptionStatus('Preparing audio...');
            
            console.log("Starting transcription for blob size:", audioBlob.size);
            
            // Create FormData for upload
            const formData = new FormData();
            const fileExt = recordingMimeType.split('/')[1] || 'webm';
            formData.append('audio', audioBlob, `recording.${fileExt}`);
            
            setTranscriptionStatus('Transcribing with Whisper...');
            
            // Send to server for transcription with timeout for mobile networks
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout
            
            try {
                const response = await fetch(`${base_url}/api/transcribe`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'X-Conversation-ID': localStorage.getItem('imavenconvoid'),
                        'X-Thread-ID': localStorage.getItem('imavenconvoid'),
                        'X-Asst-ID': localStorage.getItem('asstId'),
                        'X-Directive-ID': localStorage.getItem('directiveId'),
                        'x-apikey': apikey
                    },
                    signal: controller.signal
                });
                
                clearTimeout(timeoutId);
                
                // Handle response
                if (!response.ok) {
                    throw new Error(`Server responded with ${response.status}`);
                }
                
                const data = await response.json();
                console.log("Transcription received:", data);
                
                // Add transcription to input field
                if (data.transcription && data.transcription.trim()) {
                    setCurrentMessage(prev => {
                        const prefix = prev.trim() ? `${prev.trim()} ` : '';
                        return `${prefix}${data.transcription.trim()}`;
                    });
                    
                    // Focus input field after transcription
                    setTimeout(() => {
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }, 300);
                    
                    setTranscriptionStatus('');
                } else {
                    setTranscriptionStatus('No speech detected');
                    setTimeout(() => setTranscriptionStatus(''), 3000);
                }
            } catch (fetchError) {
                if (fetchError.name === 'AbortError') {
                    console.error("Transcription request timed out");
                    setTranscriptionStatus('Transcription timed out');
                } else {
                    throw fetchError;
                }
            }
        } catch (error) {
            console.error("Transcription error:", error);
            setTranscriptionStatus('Transcription failed');
            setTimeout(() => setTranscriptionStatus(''), 3000);
        } finally {
            setIsTranscribing(false);
        }
    };

    // Enhanced cleanup function to better handle mobile scenarios
    useEffect(() => {
        return () => {
            if (mediaRecorder) {
                if (mediaRecorder.state === 'recording') {
                    mediaRecorder.stop();
                }
                
                if (mediaRecorder.timerInterval) {
                    clearInterval(mediaRecorder.timerInterval);
                }
                
                if (mediaRecorder.visibilityCleanup) {
                    mediaRecorder.visibilityCleanup();
                }
                
                // Ensure all tracks are stopped
                if (mediaRecorder.stream) {
                    mediaRecorder.stream.getTracks().forEach(track => track.stop());
                }
            }
        };
    }, [mediaRecorder]);

    return (
        <>
            {loading ? (
                <LoadingOverlay darkMode={darkMode}>
                    <img src={LoadingIcon} alt="Loading..." width={'20px'} height={'20px'} />
                </LoadingOverlay>
            ) : (
                <FadeInWrapper loaded={!loading}>
                    {error.length > 0 ? (
                        <div>{error}</div>
                    ) : isIframe ? (
                        <div style={{ width: "320px", borderRadius: "12px", height: '500px', bottom: '1', zIndex: '10002' }}>
                            <IframeHeader darkMode={darkMode}>
                                <div style={{
                                    width: '100%', display: 'flex', height: '70%',
                                    justifyContent: 'space-between', alignItems: 'center'
                                }}>
                                    <LogoWrapper>
                                        <Logo url={ImavenIcon}></Logo>
                                        <strong>We're Here to Help!</strong>
                                    </LogoWrapper>
                                    <ExitButtonWrapper>
                                        <img onClick={closeChat} src={`${base_url}/icons/exist-icon?api_key=${apikey}`} />
                                    </ExitButtonWrapper>
                                </div>
                                <div style={{
                                    width: '100%', height: '25%',
                                    borderTop: "1px solid #cccccc",
                                    display: 'flex', alignItems: 'center', lineHeight: '1'
                                }}>
                                    <span>&nbsp;&nbsp;Instant AI assistance.</span>
                                </div>
                            </IframeHeader>
                            <IFrameChatWindow darkMode={darkMode}>

                                <MessagesContiner ref={listRef} darkMode={darkMode} onClick={handleMessagesContainerClick}>
                                    {messages.map((item, index) => (
                                        <ChatMessage message={item.message} type={item.type} apikey={apikey} darkMode={darkMode}
                                            key={index} loading={loadingMessage && index === messages.length - 1} timestamp={item.timestamp} />
                                    ))}
                                </MessagesContiner>

                                <SendMessageWrapper darkMode={darkMode}>
                                    <input name="files" type="file" id="file-input" style={{ display: 'none' }}
                                        ref={uploadRef}
                                        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                                        multiple onChange={handleFileChange} darkMode={darkMode} />

                                    <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                                        <img src={`${base_url}/icons/upload-icon?api_key=${apikey}`}
                                            alt="upload icon" />
                                    </IconWrapper>

                                    <IconWrapper onClick={openEmojiPicker}>
                                        <img src={`${base_url}/icons/emoji-icon?api_key=${apikey}`}
                                            alt="emoji icon" />
                                    </IconWrapper>

                                    <Input ref={inputRef} placeholder='Type your message...' type='text'
                                        value={currentMessage} onChange={handleInputChange} disabled={loadingMessage}
                                        onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()} darkMode={darkMode}
                                        autoFocus={false} />

                                    {currentMessage.trim() ? (
                                        // Show send button when there's text
                                        <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                            <img src={`${base_url}/icons/send-btn?api_key=${apikey}`}
                                                alt="send icon" />
                                        </IconWrapper>
                                    ) : (
                                        // Show mic button when input is empty
                                        isRecording ? (
                                            <RecordingIcon
                                                onClick={toggleRecording}
                                                darkMode={darkMode}
                                                style={{ marginRight: '5px' }}
                                            >
                                                <img
                                                    src={darkMode ? MicRecordingIconDark : MicRecordingIcon}
                                                    alt="Stop recording"
                                                />
                                                <RecordingTimer darkMode={darkMode}>
                                                    {formatTime(recordingTime)}
                                                </RecordingTimer>
                                            </RecordingIcon>
                                        ) : (
                                            <IconWrapper
                                                onClick={toggleRecording}
                                                disabled={loadingMessage || isTranscribing}
                                                darkMode={darkMode}
                                                style={{ marginRight: '5px' }}
                                            >
                                                <img
                                                    src={darkMode ? MicIconDark : MicIcon}
                                                    alt="Start recording"
                                                />
                                                {isTranscribing && (
                                                    <RecordingStatus darkMode={darkMode}>
                                                        {transcriptionStatus}
                                                    </RecordingStatus>
                                                )}
                                            </IconWrapper>
                                        )
                                    )}
                                </SendMessageWrapper>

                                <PoweredByWrapper darkMode={darkMode}>
                                    <PoweredBy href="https://imavenai.com/" target="_blank" rel="noopener noreferrer">
                                        <PowerByText darkMode={darkMode}>POWERED BY</PowerByText>
                                        <img src={base_url + `/icons/small-logo?api_key=${apikey}`} alt="IMAVEN Logo"
                                            width={'25px'} height={'20px'} />
                                        <PowerByText darkMode={darkMode}>IMAVEN</PowerByText>
                                    </PoweredBy>
                                </PoweredByWrapper>

                            </IFrameChatWindow>
                        </div>
                    ) : isMobile ? (
                        <ChatPageMobile
                            darkMode={darkMode}
                            messages={messages}
                            expertData={expertData}
                            sidebarOpen={sidebarOpen}
                            toggleSidebar={toggleSidebar}
                            toggleMode={toggleMode}
                            listRef={listRef}
                            handleMessagesContainerClick={handleMessagesContainerClick}
                            loadingMessage={loadingMessage}
                            emojiPickerOn={emojiPickerOn}
                            data={data}
                            emojiSelected={emojiSelected}
                            uploadRef={uploadRef}
                            handleFileChange={handleFileChange}
                            uploadDoc={uploadDoc}
                            openEmojiPicker={openEmojiPicker}
                            inputRef={inputRef}
                            currentMessage={currentMessage}
                            handleInputChange={handleInputChange}
                            sendMessage={sendMessage}
                            apikey={apikey}
                            base_url={base_url}
                            isRecording={isRecording}
                            recordingTime={recordingTime}
                            toggleRecording={toggleRecording}
                            isTranscribing={isTranscribing}
                            transcriptionStatus={transcriptionStatus}
                            formatTime={formatTime}
                        />
                    ) : (
                        <div style={{
                            display: 'flex', flexDirection: 'row', backgroundColor: darkMode ? 'rgb(30, 30, 30)' : 'rgb(246, 248, 252)', height: '100vh'
                        }}>
                            <div style={{ width: '15%', padding: '20px 10px 20px 20px' }}>
                                <div style={{
                                    height: '100%', backgroundColor: darkMode ? '#1E1E1E' : '#ECEFF1',
                                    borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                    overflow: 'hidden'
                                }}>
                                    <SideBar darkMode={darkMode}></SideBar>
                                </div>

                            </div>

                            <div style={{ width: '65%', padding: '20px 10px 20px 10px' }}>

                                <Container>
                                    <ChatWindow darkMode={darkMode}>

                                        <MessagesContiner ref={listRef} darkMode={darkMode} onClick={handleMessagesContainerClick}>
                                            {messages.map((item, index) => (
                                                <ChatMessage message={item.message} type={item.type} apikey={apikey} darkMode={darkMode}
                                                    key={index} loading={loadingMessage && index === messages.length - 1} />
                                            ))}
                                        </MessagesContiner>

                                        <div style={{ position: 'relative' }}>
                                            {emojiPickerOn &&
                                                <div style={{ bottom: '138%', position: 'absolute', left: "1%", zIndex: '100' }}>
                                                    <Picker data={data} onEmojiSelect={emojiSelected} theme="light" />
                                                </div>
                                            }

                                            <SendMessageWrapper darkMode={darkMode}>
                                                <input name="files" type="file" id="file-input" style={{ display: 'none' }}
                                                    ref={uploadRef}
                                                    accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ppt,.txt,.html,.md,.csv,.json,.epub"
                                                    multiple onChange={handleFileChange} darkMode={darkMode} />

                                                <IconWrapper disabled={loadingMessage} onClick={uploadDoc}>
                                                    <img src={`${base_url}/icons/upload-icon?api_key=${apikey}`}
                                                        alt="upload icon" />
                                                </IconWrapper>

                                                <IconWrapper onClick={openEmojiPicker}>
                                                    <img src={`${base_url}/icons/emoji-icon?api_key=${apikey}`}
                                                        alt="emoji icon" />
                                                </IconWrapper>

                                                <Input ref={inputRef} placeholder='Type your message...' type='text'
                                                    value={currentMessage} onChange={handleInputChange} disabled={loadingMessage}
                                                    onKeyDown={(e) => e.key === 'Enter' && !loadingMessage && sendMessage()} darkMode={darkMode}
                                                    autoFocus={false} />

                                                {currentMessage.trim() ? (
                                                    // Show send button when there's text
                                                    <IconWrapper style={{ marginRight: '5px' }} onClick={sendMessage} disabled={loadingMessage}>
                                                        <img src={`${base_url}/icons/send-btn?api_key=${apikey}`}
                                                            alt="send icon" />
                                                    </IconWrapper>
                                                ) : (
                                                    // Show mic button when input is empty
                                                    isRecording ? (
                                                        <RecordingIcon
                                                            onClick={toggleRecording}
                                                            darkMode={darkMode}
                                                            style={{ marginRight: '5px' }}
                                                        >
                                                            <img
                                                                src={darkMode ? MicRecordingIconDark : MicRecordingIcon}
                                                                alt="Stop recording"
                                                            />
                                                            <RecordingTimer darkMode={darkMode}>
                                                                {formatTime(recordingTime)}
                                                            </RecordingTimer>
                                                        </RecordingIcon>
                                                    ) : (
                                                        <IconWrapper
                                                            onClick={toggleRecording}
                                                            disabled={loadingMessage || isTranscribing}
                                                            darkMode={darkMode}
                                                            style={{ marginRight: '5px' }}
                                                        >
                                                            <img
                                                                src={darkMode ? MicIconDark : MicIcon}
                                                                alt="Start recording"
                                                            />
                                                            {isTranscribing && (
                                                                <RecordingStatus darkMode={darkMode}>
                                                                    {transcriptionStatus}
                                                                </RecordingStatus>
                                                            )}
                                                        </IconWrapper>
                                                    )
                                                )}
                                            </SendMessageWrapper>
                                        </div>

                                    </ChatWindow>
                                </Container>

                            </div>

                            <div style={{ width: '20%', padding: '20px 20px 20px 10px' }}>

                                <SideContainer darkMode={darkMode}>

                                    <div style={{
                                        padding: '10px', height: '100%', overflowY: 'auto',
                                        position: 'relative'
                                    }}>
                                        <div style={{
                                            width: '20px', height: '20px',
                                            position: 'absolute', right: '10px',
                                            cursor: 'pointer'
                                        }} onClick={toggleMode}>
                                            <img src={darkMode ? LightModeIcon : NightModeIcon} />
                                        </div>

                                        <h2>Adviser Info</h2>

                                        {/* image */}
                                        <ExpertImageWrapper>
                                            <img
                                                src={expertData.imgUrl}
                                                width={'100%'}
                                                height={'100%'}
                                                alt='expert image'
                                                style={{ borderRadius: '10px' }} // Add corner radius here
                                            />
                                        </ExpertImageWrapper>

                                        <ExpertTitle darkMode={darkMode}>
                                            <div style={{ height: '100%', padding: '20px' }}>
                                                <ExpertName darkMode={darkMode}>{expertData.expertName}</ExpertName>
                                                <ExpertRole>{expertData.expertTilte}</ExpertRole>
                                                <Rating rating={expertData.stars} reviews={expertData.reviewAmount} />
                                            </div>
                                        </ExpertTitle>

                                        <InfoContainer>
                                            <ContactItem>
                                                <InfoIconWrapper darkMode={darkMode}>
                                                    <img src={LocationIcon} width={'25px'} alt='location icon' />
                                                </InfoIconWrapper>
                                                <Text darkMode={darkMode}>{expertData.expertInfo.location}</Text>
                                            </ContactItem>

                                            <ContactItem>
                                                <InfoIconWrapper darkMode={darkMode}>
                                                    <img src={ClockIcon} alt='clock icon' />
                                                </InfoIconWrapper>
                                                <Text darkMode={darkMode}>
                                                    <HighlightedText darkMode={darkMode}>Open</HighlightedText> · Closes {expertData.expertInfo.hourClose}
                                                </Text>
                                            </ContactItem>

                                            <ContactItem>
                                                <InfoIconWrapper darkMode={darkMode}>
                                                    <img src={WebIcon} alt='web icon' />
                                                </InfoIconWrapper>
                                                <Text darkMode={darkMode}>{expertData.expertInfo.site}</Text>
                                            </ContactItem>

                                            <ContactItem>
                                                <InfoIconWrapper darkMode={darkMode}>
                                                    <img src={EmailIcon} alt='email icon' />
                                                </InfoIconWrapper>
                                                <Text darkMode={darkMode}>{expertData.expertInfo.email}</Text>
                                            </ContactItem>

                                            <ContactItem>
                                                <InfoIconWrapper darkMode={darkMode}>
                                                    <img src={PhoneIcon} alt='phone icon' />
                                                </InfoIconWrapper>
                                                <Text darkMode={darkMode}>{expertData.expertInfo.phone}</Text>
                                            </ContactItem>

                                            <ContactItem>
                                                <InfoIconWrapper darkMode={darkMode}>
                                                    <img src={PageIcon} alt='page icon' />
                                                </InfoIconWrapper>
                                                <Text darkMode={darkMode}>{expertData.expertInfo.about}</Text>
                                            </ContactItem>

                                        </InfoContainer>
                                    </div>

                                </SideContainer>

                            </div>
                        </div>
                    )}
                </FadeInWrapper>
            )}

            {showPopup && (
                <PopupOverlay onClick={() => setShowPopup(false)}>
                    <PopupContent darkMode={darkMode} onClick={(e) => e.stopPropagation()}>
                        <strong>Thank you for sharing your information!</strong>
                        <p>
                            We are now processing your details and will forward them to a human adviser for further review.
                            To continue your consultation and connect with an adviser, please click the button below.
                            Your conversation will proceed on the Imaven platform.
                        </p>
                        <PopupButton onClick={handlePopupClick}>
                            Continue to Advisor
                        </PopupButton>
                    </PopupContent>
                </PopupOverlay>
            )}
        </>
    );
};

export default ChatPage;
